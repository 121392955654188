@font-face {
  font-family: 'Open Sans Regular';
  src: url('./fonts/OpenSans-Regular.ttf') format('opentype');
}
@font-face {
  font-family: 'Open Sans Extra Bold';
  src: url('./fonts/open-sans.extrabold.ttf') format('opentype');
}
@font-face {
  font-family: 'Open Sans Bold';
  src: url('./fonts/open-sans.bold.ttf') format('opentype');
}
@font-face {
  font-family: 'Open Sans Italic';
  src: url('./fonts/open-sans.italic.ttf') format('opentype');
}

body {
  margin: 0;
  padding: 0;
  font-family: "industry-inc-base";
}

html, body {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "industry-inc-base";
  width: 100%;
  height: 100%;
}
p{
  font-family: "industry-inc-base";
}

